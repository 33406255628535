module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Nordic House Exhibitions","short_name":"Exhibitions","start_url":"/","background_color":"#fff","theme_color":"#000","display":"standalone","icon":"src/assets/img/NordicHouseLogo_Icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d6d17eca46fd3d3618b903035740bc5b"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":702,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*","/is/*","/exhibitions/how-did-i-get-to-the-bomb-shelter/","/exhibitions/how-did-i-get-to-the-bomb-shelter/*","/is/exhibitions/how-did-i-get-to-the-bomb-shelter/","/is/exhibitions/how-did-i-get-to-the-bomb-shelter/*","/exhibitions/how-did-i-get-to-the-bomb-shelter/artists/*","/exhibitions/how-did-i-get-to-the-bomb-shelter/articles/*","/is/exhibitions/how-did-i-get-to-the-bomb-shelter/artists/*","/is/exhibitions/how-did-i-get-to-the-bomb-shelter/articles/*"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
