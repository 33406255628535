exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-exhibitions-en-js": () => import("./../../../src/pages/exhibitions.en.js" /* webpackChunkName: "component---src-pages-exhibitions-en-js" */),
  "component---src-pages-exhibitions-is-js": () => import("./../../../src/pages/exhibitions.is.js" /* webpackChunkName: "component---src-pages-exhibitions-is-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-is-js": () => import("./../../../src/pages/index.is.js" /* webpackChunkName: "component---src-pages-index-is-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-exhibit-js": () => import("./../../../src/templates/exhibit.js" /* webpackChunkName: "component---src-templates-exhibit-js" */),
  "component---src-templates-map-js": () => import("./../../../src/templates/map.js" /* webpackChunkName: "component---src-templates-map-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

